import { Serializable } from 'types/interfaces';

export interface InscritosAPII {
  id?: string;
  nombre?: string;
  apellidos?: string;
  cups?: string;
  nif?: string;
  email?: string;
  domicilio?: string;
  poblacion?: string;
  provincia?: string;
  razonSocialEmpresa?: string;
  nifEmpresa?: string;
  codigoPostal?: string;
  direccionCompleta?: string;
  telefono?: string;
  latitud?: number;
  longitud?: number;
  tipoInscrito?: string;
  tipoPropiedad?: string;
  tipoConsumo?: string;
  tarifaPVPC?: string;
  tarifa?: string;
  fechaInscripcion?: string;
  modalidadInscrito?: string;
  precioCompensacion?: number;
  potenciaContratadaP1?: number;
  potenciaContratadaP2?: number;
  potenciaContratadaP3?: number;
  potenciaContratadaP4?: number;
  potenciaContratadaP5?: number;
  potenciaContratadaP6?: number;
  terminoEnergiaP1?: number;
  terminoEnergiaP2?: number;
  terminoEnergiaP3?: number;
  terminoEnergiaP4?: number;
  terminoEnergiaP5?: number;
  terminoEnergiaP6?: number;
  idComunidad?: number;
  validado?: string;
  nombreComunidad?: string;
  documentos?: Array<{
    id: string;
    nombre: string;

    url: string;
  }>;
  codigoPostalFiscal?: string;

  domicilioFiscal?: string;
  margenKwH?: number;

  margenMensual?: string; // Cambia 'number | '' ' a 'string'
  OTC?: 'si' | 'no';
  observaciones?: string;
}

export class InscritosModel implements Serializable<InscritosModel> {
  id: string;

  nombre: string;

  apellidos: string;

  nif: string;

  cups: string;

  email: string;

  domicilio: string;

  poblacion: string;

  provincia: string;

  razonSocialEmpresa: string;

  nifEmpresa: string;

  codigoPostal: string;

  direccionCompleta: string;

  telefono: string;

  latitud: number;

  longitud: number;

  tipoInscrito: string;

  tipoPropiedad: string;

  tipoConsumo: string;

  tarifaPVPC: string;

  tarifa: string;

  fechaInscripcion: string;

  modalidadInscrito: string;

  precioCompensacion: number;

  potenciaContratadaP1: number;

  potenciaContratadaP2: number;

  potenciaContratadaP3: number;

  potenciaContratadaP4: number;

  potenciaContratadaP5: number;

  potenciaContratadaP6: number;

  terminoEnergiaP1: number;

  terminoEnergiaP2: number;

  terminoEnergiaP3: number;

  terminoEnergiaP4: number;

  terminoEnergiaP5: number;

  terminoEnergiaP6: number;

  nombreComunidad: string;

  idComunidad: number;

  validado: string;

  documentos: Array<{
    id: string;
    nombre: string;
    url: string;
  }>;

  codigoPostalFiscal: string;

  domicilioFiscal: string;

  margenKwH: number;

  margenMensual: string;

  OTC: boolean;

  observaciones: string;

  deserialize(input: InscritosAPII): InscritosModel {
    if (!input) {
      return this;
    }

    this.id = input.id || '';
    this.nombre = input.nombre || '';
    this.nombre = input.nombre || '';
    this.apellidos = input.apellidos || '';
    this.nif = input.nif || '';
    this.cups = input.cups || '';
    this.nombreComunidad = input.nombreComunidad || '';
    this.email = input.email || '';
    this.domicilio = input.domicilio || '';
    this.poblacion = input.poblacion || '';
    this.provincia = input.provincia || '';
    this.razonSocialEmpresa = input.razonSocialEmpresa || '';
    this.nifEmpresa = input.nifEmpresa || '';
    this.codigoPostal = input.codigoPostal || '';
    this.direccionCompleta = input.direccionCompleta || '';
    this.telefono = input.telefono || '';
    this.latitud = input.latitud || 0;
    this.longitud = input.longitud || 0;
    this.tipoInscrito = input.tipoInscrito || '';
    this.tipoPropiedad = input.tipoPropiedad || '';
    this.tipoConsumo = input.tipoConsumo || '';
    this.tarifaPVPC = input.tarifaPVPC || '';
    this.tarifa = input.tarifa || '';
    this.fechaInscripcion = input.fechaInscripcion || '';
    this.modalidadInscrito = input.modalidadInscrito || '';
    this.precioCompensacion = input.precioCompensacion || 0;
    this.potenciaContratadaP1 = input.potenciaContratadaP1 || 0;
    this.potenciaContratadaP2 = input.potenciaContratadaP2 || 0;
    this.potenciaContratadaP3 = input.potenciaContratadaP3 || 0;
    this.potenciaContratadaP4 = input.potenciaContratadaP4 || 0;
    this.potenciaContratadaP5 = input.potenciaContratadaP5 || 0;
    this.potenciaContratadaP6 = input.potenciaContratadaP6 || 0;
    this.terminoEnergiaP1 = input.terminoEnergiaP1 || 0;
    this.terminoEnergiaP2 = input.terminoEnergiaP2 || 0;
    this.terminoEnergiaP3 = input.terminoEnergiaP3 || 0;
    this.terminoEnergiaP4 = input.terminoEnergiaP4 || 0;
    this.terminoEnergiaP5 = input.terminoEnergiaP5 || 0;
    this.terminoEnergiaP6 = input.terminoEnergiaP6 || 0;
    this.idComunidad = input.idComunidad || 0;
    this.validado = input.validado || '';
    this.documentos = input.documentos || [];
    this.codigoPostalFiscal = input.codigoPostalFiscal || '';
    this.domicilioFiscal = input.domicilioFiscal || '';

    this.margenKwH = input.margenKwH || 0;

    this.margenMensual = input.margenMensual || '';
    this.OTC = input.OTC === 'si';
    this.observaciones = input.observaciones || '';
    return this;
  }

  serialize(): InscritosAPII {
    return {
      id: this.id,
      cups: this.cups,
      nombre: this.nombre,
      apellidos: this.apellidos,
      nif: this.nif,
      email: this.email,
      domicilio: this.domicilio,
      poblacion: this.poblacion,
      provincia: this.provincia,
      razonSocialEmpresa: this.razonSocialEmpresa,
      nifEmpresa: this.nifEmpresa,
      codigoPostal: this.codigoPostal,
      direccionCompleta: this.direccionCompleta,
      telefono: this.telefono,
      latitud: this.latitud,
      longitud: this.longitud,
      tipoInscrito: this.tipoInscrito,
      tipoPropiedad: this.tipoPropiedad,
      tipoConsumo: this.tipoConsumo,
      tarifaPVPC: this.tarifaPVPC,
      tarifa: this.tarifa,
      fechaInscripcion: this.fechaInscripcion,
      modalidadInscrito: this.modalidadInscrito,
      precioCompensacion: this.precioCompensacion,
      potenciaContratadaP1: this.potenciaContratadaP1,
      potenciaContratadaP2: this.potenciaContratadaP2,
      potenciaContratadaP3: this.potenciaContratadaP3,
      potenciaContratadaP4: this.potenciaContratadaP4,
      potenciaContratadaP5: this.potenciaContratadaP5,
      potenciaContratadaP6: this.potenciaContratadaP6,
      terminoEnergiaP1: this.terminoEnergiaP1,
      terminoEnergiaP2: this.terminoEnergiaP2,
      terminoEnergiaP3: this.terminoEnergiaP3,
      terminoEnergiaP4: this.terminoEnergiaP4,
      terminoEnergiaP5: this.terminoEnergiaP5,
      terminoEnergiaP6: this.terminoEnergiaP6,
      codigoPostalFiscal: this.codigoPostalFiscal,
      domicilioFiscal: this.domicilioFiscal,
      margenKwH: this.margenKwH,
      margenMensual: this.margenMensual,
      // OTC: this.OTC ? 'si' : 'no',
      observaciones: this.observaciones,
    } as InscritosAPII;
  }

  deserializeArray(inputArray: Array<InscritosAPII>): Array<InscritosModel> {
    return inputArray.map((input) => new InscritosModel().deserialize(input));
  }

  setAttr<T>(key: string, value: T): void {
    Object.assign(this, { [key]: value });
  }
}
