import React, { useEffect, useState } from 'react';
import { FormGroup, FormGroupProps } from 'core';

import { DataTable, EunoiaImageInput, LoadingButton, SectionTitle, TableHeading } from 'components';

import { Colors } from 'styles';
import I18n from 'i18n-js';
import { InscritosModel } from 'types/models/inscritos';
import InscritosServicentance from 'services/remote/Inscritos';
import FullPageSyncLoader from 'components/FullPageSyncLoader';
import Modal from 'components/Modal/Modal';
// import CircularProgressBar from 'components/ProgressBar/ProgressBar';
import { ReactComponent as LandscapeIcon } from 'assets/icons/Landscape.svg';
import { LoggingService } from 'services';
import customToast from 'components/CustomToast/CustomToast';
import { toast } from 'react-toastify';
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg';
import { FORMGROUP_TYPES } from 'core/form/FormGroup/FormGroup';

import './fix-comunidades-detalles-inscritos.module.scss';
import Button from 'components/Button/Button';
import { navigate } from 'navigation';
import { useParams } from 'react-router-dom';

import { useToggle } from 'hooks/useToogle';
import ModalMatcher from 'components/ModalMatcher/ModalMatcher';
import ErrorMessages from 'types/enums/ErrorMessages';
import styles from './comunidades-detalles-inscritos.module.scss';

interface ParameterDetalles {
  communityID: string;
}

export const ComunidadesInscritoDetalles = (props: any): JSX.Element => {
  // Props
  const {
    match: {
      params: { inscritosID },
    },
  } = props;
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [isSwitchOn, toggleSwitch] = useToggle();
  const [loadingDelete, setLoadingDelete] = useState(false);

  const MAX_MARGENES = 0.3;

  const [show, setShow] = useState(false);
  const [showSendToERP, setShowSendToERP] = useState(false);
  const handleShow = () => setShow(true);
  const handleShowSendToERP = () => setShowSendToERP(true);
  const [loading, setLoading] = useState(false);

  const [installation, setInstallation] = useState<InscritosModel>();

  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const [errors, setErrors] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [imagenDettale, setImagen] = useState<string>();
  const [actualizar, setActualizar] = useState<boolean>(false);
  // const [setpdf] = useState<boolean>(false);
  const [nameImagen, setnameImagen] = useState<string>('');
  const [indicearray, setindicearray] = useState<number>(0);
  const { communityID } = useParams<ParameterDetalles>();

  function isImage(url: any) {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
  }
  useEffect(() => {
    InscritosServicentance.getInscritosById(inscritosID)
      .then(async (response) => {
        setInstallation(response);
      })
      .then()
      .finally(() => {
        setLoadingData(false);
        setActualizar(false);
      });
  }, [actualizar]);

  const validateForm = (): boolean => {
    if (!file) {
      setSubmitDisabled(true);
      setErrors({});
      return false;
    }
    setSubmitDisabled(false);
    setErrors({});
    return true;
  };
  const submitForm = async (): Promise<boolean> => {
    setLoading(true);
    setSubmitDisabled(true);

    try {
      if (file) {
        const response = await InscritosServicentance.addGaleryImage(inscritosID, file, nameImagen);
        if (response === 201) {
          customToast.success(I18n.t('Inscrito saved!'));

          setSubmitDisabled(false);
          setFile(undefined);
          setnameImagen('');
          setActualizar(true);
        }
      }
    } catch (error) {
      toast.error(I18n.t('ErrorInscritos'));
      setSubmitDisabled(false);

      setFile(undefined);
      setActualizar(true);
    }

    setLoading(false);
    setFile(undefined);
    setnameImagen('');
    return true;
  };

  function handleChange<T>(path: string, value: T): void {
    let newValue = value;

    // Si el path es 'codigoPostalFiscal' y el valor tiene más de 4 caracteres, lo recorta a los primeros 4
    if (path === 'codigoPostalFiscal' && typeof newValue === 'string' && newValue.length > 4) {
      newValue = newValue.slice(0, 5) as unknown as T;
    }

    setInstallation(
      new InscritosModel().deserialize({
        ...installation,
        [path]: newValue,
        OTC: installation?.OTC ? 'si' : 'no',
      }),
    );

    installation?.setAttr(path, newValue);
  }

  function changeImg(e: React.MouseEvent<HTMLImageElement, MouseEvent>, index: number) {
    e.stopPropagation();

    const img = e.currentTarget;

    setImagen(img.src);
    setindicearray(index);
  }
  function changedatapDF(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) {
    e.stopPropagation();

    const img = e.currentTarget;
    setImagen(`${img.dataset.r}#zoom=80`);
    setindicearray(index);
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function handleCheckBox<T>(path: string, value: string, id: string, checked: boolean): Promise<unknown> {
    let NewValue: string;
    if (checked) {
      NewValue = 'si';
    } else {
      NewValue = 'no';
    }
    await setInstallation(
      new InscritosModel().deserialize({
        ...installation,
        [path]: NewValue,
        OTC: installation?.OTC ? 'si' : 'no',
      }),
    );

    return true;
  }
  // Effects
  const validateFormInscritos = (): boolean => {
    setSubmitDisabled(false);
    setErrors({});
    return true;
  };
  const submitReportChanges = async (InscritosUpdate: InscritosModel): Promise<boolean> => {
    const object = InscritosUpdate;
    if (object.tipoConsumo === 'domestico') {
      object.potenciaContratadaP4 = 0;
      object.potenciaContratadaP5 = 0;
      object.potenciaContratadaP6 = 0;
      object.terminoEnergiaP4 = 0;
      object.terminoEnergiaP5 = 0;
      object.terminoEnergiaP6 = 0;
    }
    try {
      await InscritosServicentance.putInscrito(object);
      customToast.success(I18n.t('Inscrito successfully updated'));
    } catch (error) {
      toast.error(I18n.t('Error updating report!'));
    }
    return true;
  };
  const submitReportChangesValidar = async (
    action: 'validar' | 'sendToERP',
    InscritosUpdate: InscritosModel,
  ): Promise<boolean> => {
    const object = InscritosUpdate;

    try {
      if (action === 'validar') {
        await InscritosServicentance.putInscritoValidado(object);
        customToast.success(I18n.t('Inscrito validado'));
      } else if (action === 'sendToERP') {
        await InscritosServicentance.sendInscritoToERP(object);

        customToast.success(I18n.t('Inscrito enviado al ERP'));
      }

      setShow(false);
      setShowSendToERP(false);
    } catch (error: any) {
      if (error.response && error.response.data) {
        if (action === 'sendToERP') {
          const { data } = error.response;

          if (data && typeof data === 'string') {
            toast.error(` ${data}`);
          }
        } else if (Object.values(ErrorMessages).includes(error.response.data)) {
          const errorKey = Object.keys(ErrorMessages).find(
            (key) => ErrorMessages[key as keyof typeof ErrorMessages] === error.response.data,
          );
          if (errorKey) {
            toast.error(I18n.t(errorKey));
          }
        }
      } else {
        switch (error.response.status) {
          case 400:
            toast.error(I18n.t('YaValidado'));
            break;
          case 401:
            toast.error(I18n.t('Not authorized'));
            break;
          case 403:
            toast.error(I18n.t('Without access privileges'));
            break;
          case 404:
            toast.error(I18n.t('Inscrit not found on system'));
            break;
          default:
            toast.error(I18n.t('Unknown error occurred'));
            break;
        }
      }
      setShow(false);
      setShowSendToERP(false);
    }
    return true;
  };
  const submitFormForInscritos = async (): Promise<boolean> => {
    setLoading(true);
    setSubmitDisabled(true);
    submitReportChanges(installation as InscritosModel);
    setLoading(false);
    setSubmitDisabled(false);
    return true;
  };
  const submitFormForInscritosValidar = async (action: 'validar' | 'sendToERP'): Promise<boolean> => {
    setLoading(true);
    setSubmitDisabled(true);
    submitReportChangesValidar(action, installation as InscritosModel);
    setLoading(false);
    setSubmitDisabled(false);
    return true;
  };

  const handleClick = () => {
    if (validateFormInscritos()) {
      LoggingService.log('handleSubmit :: ok', { installation });
      submitFormForInscritos();
    } else {
      LoggingService.log('handleSubmit :: errors', { installation, errors });
    }
  };
  const handleClickValidar = (action: 'validar' | 'sendToERP') => {
    if (validateFormInscritos()) {
      LoggingService.log('handleSubmit :: ok', { installation });
      submitFormForInscritosValidar(action);
    } else {
      LoggingService.log('handleSubmit :: errors', { installation, errors });
    }
  };

  const deleteImage = async (imagedel: any): Promise<void> => {
    // eslint-disable-next-line no-alert
    const accept = window.confirm(I18n.t('Are you sure you want to delete the document?'));
    if (!accept) {
      return;
    }

    try {
      await InscritosServicentance.deleteImage(inscritosID, imagedel.id);

      customToast.success(`${I18n.t('Inscrito deleted successfully')}!`);

      setSubmitDisabled(false);
      setActualizar(true);
      setindicearray(0);
      setImagen(undefined);
    } catch (error) {
      toast.error(`${I18n.t('Error deleting image')}.`);
      setSubmitDisabled(false);

      setActualizar(true);
      setindicearray(0);
      setImagen(undefined);
    }
  };
  const onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (validateForm()) {
      LoggingService.log('handleSubmit :: ok', { file });
      submitForm();
    } else {
      LoggingService.log('handleSubmit :: errors', { file, errors });
    }
  };

  const settingFile = (file1: File): void => {
    if (file1) {
      setFile(file1);

      setnameImagen(file1.name);
      setIsButtonDisabled(false);
    }
  };

  const arrayForcheckox: Array<FormGroupProps> = [
    {
      label: I18n.t('InscritosDetalle.tipoConsumo'),

      type: FORMGROUP_TYPES.select,
      id: 'tipoConsumo',
      path: 'tipoConsumo',
      name: 'tipoConsumo',
      placeholder: 'wwww',
      value: installation?.tipoConsumo,
      options: [
        { label: 'Domesticos', value: 'domestico' },
        { label: 'Industrial', value: 'industrial' },
      ],
    },
    {
      label: I18n.t('InscritosDetalle.tipoInscrito'),
      type: FORMGROUP_TYPES.select,
      id: 'tipoInscrito',
      path: 'tipoInscrito',
      name: 'tipoInscrito',
      placeholder: 'wwww',
      value: installation?.tipoInscrito,
      options: [
        { label: 'Particular', value: 'particular' },
        { label: 'Empresa', value: 'empresa' },
      ],
    },
  ];

  const formcheckbosleft: Array<FormGroupProps> = [
    {
      label: I18n.t('InscritosDetalle.tipoPropiedad'),

      type: FORMGROUP_TYPES.select,
      id: 'tipoPropiedad',
      path: 'tipoPropiedad',
      name: 'tipoPropiedad',
      placeholder: 'wwww',
      value: installation?.tipoPropiedad,
      options: [
        { label: 'Propietario', value: 'propietario' },
        { label: 'Alquiler', value: 'alquiler' },
        { label: 'Otros', value: 'otros' },
      ],
    },
    {
      label: I18n.t('InscritosDetalle.tipoTarifa'),

      type: FORMGROUP_TYPES.select,
      id: 'tarifa',
      path: 'tarifa',
      name: 'tarifa',

      value: installation?.tarifa,
      options: [
        { label: 'tarifa1', value: '2.0TD' },
        { label: 'tarifa2', value: '3.0TD' },
        { label: 'Otros', value: 'otros' },
      ],
    },
  ];
  const formFieldsLeftCUPS: Array<FormGroupProps> = [
    {
      label: 'CUPS',
      type: 'string',
      id: 'cups',
      path: 'cups',
      name: 'cups',
      value: installation?.cups,
      // readOnly: (installation?.validado === 'si') === true,
    },
  ];
  const formFieldsLeft: Array<FormGroupProps> = [
    {
      label: I18n.t('InscritosDetalle.nombre'),

      type: 'string',
      id: 'nombre',
      path: 'nombre',
      name: 'nombre',
      value: installation?.nombre,
      placeholder: '',

      //   // readOnly: (installation?.validado === 'si') === true,
    },
    {
      label: I18n.t('InscritosDetalle.Dni'),
      type: 'string',
      id: 'nif',
      path: 'nif',
      name: 'nif',
      placeholder: '',
      value: installation?.nif,

      //   // readOnly: (installation?.validado === 'si') === true,
    },
    {
      label: I18n.t('InscritosDetalle.telefon'),

      type: 'string',
      id: 'telefono',
      path: 'telefono',
      name: 'telefon',
      value: installation?.telefono,
      // readOnly: (installation?.validado === 'si') === true,
    },

    {
      label: I18n.t('InscritosDetalle.domicilio'),

      type: 'string',
      id: 'domicilio',
      path: 'domicilio',
      name: 'domicilio',
      value: installation?.domicilio,
      // readOnly: (installation?.validado === 'si') === true,
      placeholder: '',
    },

    {
      label: I18n.t('InscritosDetalle.codigoPostal'),

      type: 'string',
      id: 'codigoPostal',
      path: 'codigoPostal',
      name: 'codigoPostal',
      value: installation?.codigoPostal,
      placeholder: '',

      // readOnly: (installation?.validado === 'si') === true,
    },
    {
      label: I18n.t('InscritosDetalle.nifEmpresa'),
      type: 'string',
      id: 'nifEmpresa',
      path: 'nifEmpresa',
      name: 'nifEmpresa',
      value: installation?.nifEmpresa,
      placeholder: '',
    },
    ...(installation?.tipoInscrito === 'empresa'
      ? [
          {
            label: I18n.t('InscritosDetalle.domicilioFiscal'),
            type: 'string',
            id: 'domicilioFiscal',
            path: 'domicilioFiscal',
            name: 'domicilioFiscal',
            value: installation?.domicilioFiscal,
            placeholder: '',
          },
        ]
      : []),
  ];

  const formFieldsRight: Array<FormGroupProps> = [
    {
      label: I18n.t('InscritosDetalle.apellidos'),

      type: 'string',
      id: 'apellidos',
      path: 'apellidos',
      name: 'apellidos',
      value: installation?.apellidos,
      placeholder: '',

      // readOnly: (installation?.validado === 'si') === true,
    },

    {
      label: I18n.t('InscritosDetalle.Email'),
      type: 'string',
      id: 'email',
      path: 'email',
      name: 'email',
      value: installation?.email,
      placeholder: '',

      //   // readOnly: (installation?.validado === 'si') === true,
    },
    {
      label: I18n.t('InscritosDetalle.Direccion'),

      type: 'string',
      id: 'Direccion',
      path: 'direccionCompleta',
      name: 'Direccion',
      value: installation?.direccionCompleta,
      placeholder: '',

      // readOnly: (installation?.validado === 'si') === true,
    },

    {
      label: I18n.t('InscritosDetalle.poblacion'),

      type: 'string',
      id: 'poblacion',
      path: 'poblacion',
      name: 'poblacion',
      value: installation?.poblacion,
      placeholder: '',

      // readOnly: (installation?.validado === 'si') === true,
    },

    {
      label: I18n.t('InscritosDetalle.provincia'),

      type: 'string',
      id: 'provincia',
      path: 'provincia',
      name: 'provincia',
      value: installation?.provincia,
      placeholder: '',

      // readOnly: (installation?.validado === 'si') === true,
    },
    {
      label: I18n.t('InscritosDetalle.razonSocial'),
      type: 'string',
      id: 'razonSocialEmpresa',
      path: 'razonSocialEmpresa',
      name: 'razonSocialEmpresa',
      value: installation?.razonSocialEmpresa,
      placeholder: '',
    },
    ...(installation?.tipoInscrito === 'empresa'
      ? [
          {
            label: I18n.t('InscritosDetalle.codigoPostalFiscal'),
            type: 'string',
            id: 'codigoPostalFiscal',
            path: 'codigoPostalFiscal',
            name: 'codigoPostalFiscal',
            value: installation?.codigoPostalFiscal,
            placeholder: '',
          },
        ]
      : []),
  ];
  const formFieldsRightFactura: Array<FormGroupProps> = [
    {
      label: 'P 1',
      type: 'number',
      placeholder: '0',
      id: 'potenciaContratadaP1',
      path: 'potenciaContratadaP1',
      name: 'potenciaContratadaP1',
      value: installation?.potenciaContratadaP1,
      // readOnly: (installation?.validado === 'si') === true,
    },
    {
      label: 'P 2',
      type: 'number',
      placeholder: '0',
      id: 'potenciaContratadaP2',
      path: 'potenciaContratadaP2',
      name: 'potenciaContratadaP2',
      value: installation?.potenciaContratadaP2 as number,
      // readOnly: (installation?.validado === 'si') === true,
    },
    {
      label: 'P 3',
      type: 'number',
      placeholder: '0',
      id: 'potenciaContratadaP3',
      path: 'potenciaContratadaP3',
      name: 'potenciaContratadaP3',
      value: installation?.potenciaContratadaP3,
      // readOnly: (installation?.validado === 'si') === true,
    },
  ];

  const formFieldsMargenes: Array<FormGroupProps> = [
    {
      label: I18n.t('UpdateTarifaPage.margeMensual'),
      type: 'margenes',
      placeholder: '0',
      id: 'margenMensual',
      path: 'margenMensual',
      name: 'margenMensual',
      value: installation?.margenMensual,
    },
    {
      label: I18n.t('UpdateTarifaPage.margeKw'),
      type: 'margenes',
      placeholder: '',
      id: 'margenKwH',
      path: 'margenKwH',
      name: 'margenKwH',
      value: installation?.margenKwH,
      max: MAX_MARGENES,

      // readOnly: (installation?.validado === 'si') === true,
    },
  ];
  const tarifaDefault: Array<FormGroupProps> = [
    {
      label: 'P 1',
      type: 'number',
      placeholder: '0',
      id: 'Tarifa1',
      path: 'terminoEnergiaP1',
      name: 'Tarifa1',
      value: installation?.terminoEnergiaP1,
      // readOnly: (installation?.validado === 'si') === true,
    },

    {
      label: 'P 2',
      type: 'number',
      placeholder: '0',
      id: 'Tarifa2',
      path: 'terminoEnergiaP2',
      name: 'Tarifa2',
      value: installation?.terminoEnergiaP2,
      // readOnly: (installation?.validado === 'si') === true,
    },
    {
      label: 'P 3',
      type: 'number',
      placeholder: '0',
      id: 'Tarifa3',
      path: 'terminoEnergiaP3',
      name: 'Tarifa3',
      value: installation?.terminoEnergiaP3,
      // readOnly: (installation?.validado === 'si') === true,
    },
  ];
  const potenciaIndustrial: Array<FormGroupProps> = [
    {
      label: 'P 4',
      type: 'number',
      placeholder: '0',
      id: 'potenciaContratadaP4',
      path: 'potenciaContratadaP4',
      name: 'potenciaContratadaP4',
      value: installation?.potenciaContratadaP4,
      // readOnly: (installation?.validado === 'si') === true,
    },
    {
      label: 'P 5',
      type: 'number',
      placeholder: '0',
      id: 'potenciaContratadaP5',
      path: 'potenciaContratadaP5',
      name: 'potenciaContratadaP5',
      value: installation?.potenciaContratadaP5,
      // readOnly: (installation?.validado === 'si') === true,
    },
    {
      label: 'P 6',
      type: 'number',
      placeholder: '0',
      id: 'potenciaContratadaP6',
      path: 'potenciaContratadaP6',
      name: 'potenciaContratadaP6',
      value: installation?.potenciaContratadaP6,
      // readOnly: (installation?.validado === 'si') === true,
    },
  ];
  const tarifaIndustrial: Array<FormGroupProps> = [
    {
      label: 'P 4',
      type: 'number',
      placeholder: '0',
      id: 'Tarifa4',
      path: 'terminoEnergiaP4',
      name: 'Tarifa4',
      value: installation?.terminoEnergiaP4,
      // readOnly: (installation?.validado === 'si') === true,
    },

    {
      label: 'P 5',
      type: 'number',
      placeholder: '0',
      id: 'Tarifa5',
      path: 'terminoEnergiaP5',
      name: 'Tarifa5',
      value: installation?.terminoEnergiaP5,
      // readOnly: (installation?.validado === 'si') === true,
    },
    {
      label: 'P 6',
      type: 'number',
      placeholder: '0',
      id: 'Tarifa6',
      path: 'terminoEnergiaP6',
      name: 'Tarifa6',
      value: installation?.terminoEnergiaP6,
      // readOnly: (installation?.validado === 'si') === true,
    },
  ];
  const formprecioCompensacion: Array<FormGroupProps> = [
    {
      label: I18n.t('InscritosDetalle.precioCompensacion'),
      type: 'number',
      placeholder: '0',
      id: 'precioCompensacion',
      path: 'precioCompensacion',
      name: 'precioCompensacion',
      value: installation?.precioCompensacion,
      // readOnly: (installation?.validado === 'si') === true,
    },
  ];
  const formFieldsRightFacturaCheckbox: Array<FormGroupProps> = [
    {
      label: I18n.t('tarifaIndexada'),
      type: 'checkbox',
      id: 'tarifaPVPC',
      path: 'tarifaPVPC',
      name: 'tarifaPVPC',
      checked: installation?.tarifaPVPC === 'si',
      value: installation?.tarifaPVPC === 'si',
      // readOnly: (installation?.validado === 'si') === true,
    },
  ];

  const formFieldsObservation: Array<FormGroupProps> = [
    {
      // label: I18n.t('observaciones'),
      type: 'textarea',
      id: 'observaciones',
      path: 'observaciones',
      name: 'observaciones',
      value: installation?.observaciones,
      // readOnly: (installation?.validado === 'si') === true,
    },
  ];
  const headings: Array<TableHeading> = [
    { label: I18n.t('Documentos'), type: 'image', key: 'url' },

    {
      label: I18n.t('Actions'),
      type: 'actions',
      key: '',
      actions: [
        {
          label: I18n.t('Delete'),
          icon: <TrashIcon height={25} width={25} fill={Colors.COLOR_ELECSUMGREEN} />,
          onActionClick: deleteImage,
        },
      ],
    },
  ];

  const inputValue = document.getElementById('codigoPostal') as HTMLInputElement;
  if (inputValue) {
    inputValue.maxLength = 5;
  }

  const deleteConfiguracion = async (idConfiguracion: string | undefined): Promise<void> => {
    setLoadingDelete(true);
    if (idConfiguracion) {
      try {
        await InscritosServicentance.deleteInscrito(idConfiguracion);

        customToast.success(`${I18n.t('Deleted successfully inscrit')}!`);

        navigate('/tecnic/inscritos');
      } catch (error) {
        toast.error(`${I18n.t('Error deleting inscrito')}.`);
        setLoadingDelete(false);
      }
    }
  };
  function downloadElement(value: string | undefined, type: string) {
    if (value) {
      const url = value;
      const a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.download = `download.${type}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }
  }

  return (
    <div className={styles.root}>
      {loadingData ? (
        <FullPageSyncLoader icon />
      ) : (
        <>
          <form className={styles.form}>
            <div className={styles.posotionradio}>
              <div className={styles.postflex}>
                <SectionTitle text={I18n.t('Inscritos')} />
              </div>
              <div className={styles.positionValidar}>
                {/* {installation?.validado === 'si' ? (
                  <LoadingButton type="submit" text={I18n.t('Rebutjar')} />
                ) : ( */}
                <div className={styles.postflexbutton}>
                  {/* <LoadingButton className={styles.buttonSecondary} type="submit" text={I18n.t('Rebutjar')} /> */}
                  {installation?.validado === 'no' ? (
                    <LoadingButton type="button" text={I18n.t('Validar')} onClick={handleShow} />
                  ) : (
                    <LoadingButton
                      type="button"
                      text={I18n.t('sendtoERP')}
                      onClick={handleShowSendToERP}
                      variant="secondary"
                    />
                  )}
                </div>
                {/* )} */}
              </div>
            </div>

            <div className={styles.flex}>
              <SectionTitle text={I18n.t('Dades personals')} />
            </div>

            <div className={styles.containerForms} style={{ marginBottom: '1rem' }}>
              <div className={styles.containerForm}>
                {formFieldsLeftCUPS.map((formGroup: FormGroupProps) => (
                  <FormGroup
                    key={formGroup.id}
                    label={formGroup.label}
                    type={formGroup.type}
                    id={formGroup.id}
                    path={formGroup.path}
                    name={formGroup.name}
                    placeholder={formGroup.placeholder}
                    value={formGroup.value}
                    required={formGroup.required}
                    readOnly={formGroup.readOnly}
                    className={formGroup.className}
                    onChange={handleChange}
                    maxLenght={200}
                  />
                ))}
              </div>
            </div>
            <div className={styles.containerForms}>
              <div className={styles.containerForm}>
                {formFieldsLeft.map((formGroup: FormGroupProps) => (
                  <FormGroup
                    key={formGroup.id}
                    label={formGroup.label}
                    type={formGroup.type}
                    id={formGroup.id}
                    path={formGroup.path}
                    name={formGroup.name}
                    placeholder={formGroup.placeholder}
                    value={formGroup.value}
                    required={formGroup.required}
                    readOnly={formGroup.readOnly}
                    className={formGroup.className}
                    onChange={handleChange}
                    maxLenght={200}
                  />
                ))}
                {arrayForcheckox.map((formGroup: FormGroupProps) => (
                  <FormGroup
                    key={formGroup.id}
                    label={formGroup.label}
                    type={formGroup.type}
                    id={formGroup.id}
                    path={formGroup.path}
                    name={formGroup.name}
                    placeholder={formGroup.placeholder}
                    value={formGroup.value}
                    required={formGroup.required}
                    onChange={handleChange}
                    options={formGroup.options}
                  />
                ))}
              </div>
              <div className={styles.containerForms}>
                <div className={styles.containerForm}>
                  {formFieldsRight.map((formGroup: FormGroupProps) => (
                    <FormGroup
                      key={formGroup.id}
                      label={formGroup.label}
                      type={formGroup.type}
                      id={formGroup.id}
                      path={formGroup.path}
                      name={formGroup.name}
                      placeholder={formGroup.placeholder}
                      value={formGroup.value}
                      required={formGroup.required}
                      readOnly={formGroup.readOnly}
                      className={formGroup.className}
                      onChange={handleChange}
                      maxLenght={200}
                    />
                  ))}

                  {formcheckbosleft.map((formGroup: FormGroupProps) => (
                    <FormGroup
                      key={formGroup.id}
                      label={formGroup.label}
                      type={formGroup.type}
                      id={formGroup.id}
                      path={formGroup.path}
                      name={formGroup.name}
                      placeholder={formGroup.placeholder}
                      value={formGroup.value}
                      required={formGroup.required}
                      onChange={handleChange}
                      options={formGroup.options}
                    />
                  ))}
                </div>
              </div>
            </div>

            <div className={styles.linea} />

            <div className={styles.flex} style={{ padding: '15px 0px  0px  0px' }}>
              <SectionTitle text={I18n.t('Dades factura')} className={styles.SectionPotencias} />
            </div>
            <div className={styles.containerFormsTest}>
              <div className={styles.containerFormsTest1}>
                <div>
                  <SectionTitle text={I18n.t('Potencias')} className={styles.SectionTarfa} />
                  <div className={styles.containerPotencia}>
                    {formFieldsRightFactura.map((formGroup: FormGroupProps) => (
                      <FormGroup
                        key={formGroup.id}
                        label={formGroup.label}
                        type={formGroup.type}
                        id={formGroup.id}
                        path={formGroup.path}
                        name={formGroup.name}
                        placeholder={formGroup.placeholder}
                        value={formGroup.value}
                        required={formGroup.required}
                        readOnly={formGroup.readOnly}
                        className={formGroup.className}
                        onChange={handleChange}
                        maxLenght={200}
                      />
                    ))}

                    {installation?.tipoConsumo === 'industrial'
                      ? potenciaIndustrial.map((formGroup: FormGroupProps) => (
                          <FormGroup
                            key={formGroup.id}
                            label={formGroup.label}
                            type={formGroup.type}
                            id={formGroup.id}
                            path={formGroup.path}
                            name={formGroup.name}
                            placeholder={formGroup.placeholder}
                            value={formGroup.value}
                            required={formGroup.required}
                            readOnly={formGroup.readOnly}
                            className={formGroup.className}
                            onChange={handleChange}
                            maxLenght={200}
                          />
                        ))
                      : null}
                  </div>
                </div>

                <div>
                  <SectionTitle text={I18n.t('Tarifa')} className={styles.SectionTarfa} />
                  <div className={styles.containerPotencia}>
                    {tarifaDefault.map((formGroup: FormGroupProps) => (
                      <FormGroup
                        key={formGroup.id}
                        label={formGroup.label}
                        type={formGroup.type}
                        id={formGroup.id}
                        path={formGroup.path}
                        name={formGroup.name}
                        placeholder={formGroup.placeholder}
                        value={formGroup.value}
                        required={formGroup.required}
                        readOnly={formGroup.readOnly}
                        className={formGroup.className}
                        onChange={handleChange}
                        maxLenght={200}
                      />
                    ))}
                    {installation?.tipoConsumo === 'industrial'
                      ? tarifaIndustrial.map((formGroup: FormGroupProps) => (
                          <FormGroup
                            key={formGroup.id}
                            label={formGroup.label}
                            type={formGroup.type}
                            id={formGroup.id}
                            path={formGroup.path}
                            name={formGroup.name}
                            placeholder={formGroup.placeholder}
                            value={formGroup.value}
                            required={formGroup.required}
                            readOnly={formGroup.readOnly}
                            className={formGroup.className}
                            onChange={handleChange}
                            maxLenght={200}
                          />
                        ))
                      : null}
                  </div>
                </div>
                <div className={styles.containerPotenciapRECIO}>
                  {formprecioCompensacion.map((formGroup: FormGroupProps) => (
                    <FormGroup
                      key={formGroup.id}
                      label={formGroup.label}
                      type={formGroup.type}
                      id={formGroup.id}
                      path={formGroup.path}
                      name={formGroup.name}
                      placeholder={formGroup.placeholder}
                      value={formGroup.value}
                      required={formGroup.required}
                      readOnly={formGroup.readOnly}
                      className={formGroup.className}
                      onChange={handleChange}
                      maxLenght={200}
                    />
                  ))}
                </div>

                <div className={styles.MargenTopCheckbox}>
                  {formFieldsRightFacturaCheckbox.map((formGroup: FormGroupProps) => (
                    <FormGroup
                      key={formGroup.id}
                      label={formGroup.label}
                      type={formGroup.type}
                      id={formGroup.id}
                      path={formGroup.path}
                      name={formGroup.name}
                      placeholder={formGroup.placeholder}
                      value={formGroup.value}
                      required={formGroup.required}
                      readOnly={formGroup.readOnly}
                      className={formGroup.className}
                      checked={formGroup.checked}
                      onChange={handleCheckBox}
                      maxLenght={200}
                    />
                  ))}
                </div>

                {installation?.tarifaPVPC === 'si' ? (
                  <div>
                    <SectionTitle text={I18n.t('UpdateTarifaPage.margenesTecnico')} className={styles.SectionTarfa} />
                    <div className={styles.containerMargenes}>
                      {formFieldsMargenes.map((formGroup: FormGroupProps) => (
                        <FormGroup
                          key={formGroup.id}
                          label={formGroup.label}
                          type={formGroup.type}
                          id={formGroup.id}
                          path={formGroup.path}
                          name={formGroup.name}
                          placeholder={formGroup.placeholder}
                          value={formGroup.value}
                          required={formGroup.required}
                          readOnly={formGroup.readOnly}
                          className={formGroup.className}
                          onChange={handleChange}
                          maxLenght={200}
                          step={0.01}
                          min={formGroup.min}
                          max={formGroup.max}
                        />
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className={styles.containerFormsTest2}>
                {installation!.documentos.length > 0 ? (
                  <div>
                    <div className={styles.styleDivgeneral}>
                      {isImage(imagenDettale) === true ||
                      isImage(installation?.documentos[indicearray].url) === true ? (
                        <>
                          <img
                            className={styles.imagenMosttrar}
                            role="presentation"
                            src={
                              imagenDettale === undefined ? installation?.documentos[indicearray].url : imagenDettale
                            }
                            alt={imagenDettale}
                          />
                          <Button
                            type="button"
                            onClick={() =>
                              downloadElement(
                                imagenDettale === undefined ? installation?.documentos[indicearray].url : imagenDettale,
                                'png',
                              )
                            }
                            className={styles.buttonDownload}
                            variant="secondary"
                            text={I18n.t('download')}
                          />
                        </>
                      ) : (
                        <>
                          <object
                            className={styles.imagenMosttrar}
                            data={
                              imagenDettale === undefined
                                ? `${installation?.documentos[indicearray].url}#zoom=80`
                                : `${imagenDettale}#zoom=80`
                            }
                            type="application/pdf"
                            aria-labelledby="pdf"
                            width="100%"
                            height="500"
                          />

                          <Button
                            type="button"
                            className={styles.buttonDownload}
                            onClick={() =>
                              downloadElement(
                                imagenDettale === undefined ? installation?.documentos[indicearray].url : imagenDettale,
                                'pdf',
                              )
                            }
                            variant="secondary"
                            text={I18n.t('download')}
                          />
                        </>
                      )}
                    </div>
                    <div className={styles.test}>
                      {installation?.documentos.map((docu, index) =>
                        isImage(docu.url) === true ? (
                          <img
                            role="presentation"
                            src={docu.url}
                            alt="Imagen3"
                            height={100}
                            className={styles.imagenThumnail}
                            onClick={(e) => changeImg(e, index)}
                          />
                        ) : (
                          <button
                            className={styles.imagenThumnail}
                            type="button"
                            data-r={docu.url}
                            onClick={(e) => changedatapDF(e, index)}
                          >
                            {' '}
                            {docu.nombre}
                          </button>
                        ),
                      )}
                    </div>
                  </div>
                ) : (
                  <div className={styles.ResultadosDocumentos}>
                    <p> {I18n.t('Noinscritos')}</p>
                  </div>
                )}
              </div>
            </div>
          </form>
          <div className={styles.linea} />

          <div className={styles.containerFormsTest}>
            <div className={styles.direccioncolum}>
              <SectionTitle text={I18n.t('Archivo adjunto')} />
              <form className={styles.form} onSubmit={onSubmit}>
                <div className={styles.containerFormsTestHeight}>
                  <div className={styles.containerForm}>
                    <div className="col-12">
                      <div className={styles.imagenDatatable}>
                        {installation?.documentos && installation?.documentos.length > 0 ? (
                          <DataTable headings={headings} rows={installation?.documentos} loading={loading} />
                        ) : null}
                      </div>
                    </div>
                    <div>
                      <div className={styles.imagenButton}>
                        {actualizar || (
                          <EunoiaImageInput
                            id="img-galeria"
                            label={I18n.t('add all')}
                            note={I18n.t('Peso Documento')}
                            fileName=""
                            icon={<LandscapeIcon height={20} width={20} fill={Colors.COLOR_GRAY_400} />}
                            type="all"
                            action={settingFile}
                          />
                        )}

                        <div className={styles.actions}>
                          <LoadingButton
                            type="submit"
                            text={I18n.t('add docu')}
                            disabled={isButtonDisabled || submitDisabled}
                            loading={loading}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            {show ? (
              <Modal
                title={I18n.t('mensajeModal') + installation?.nombre + I18n.t('spacio') + installation?.apellidos}
                body={I18n.t('mensajeModalBody')}
                onClick={() => handleClickValidar('validar')}
                testbutton={I18n.t('Confirmar')}
                onClick2={() => setShow(false)}
              />
            ) : null}

            {showSendToERP ? (
              <Modal
                title={
                  I18n.t('mensajeModalSendToERP') + installation?.nombre + I18n.t('spacio') + installation?.apellidos
                }
                body={I18n.t('mensajeModalBody')}
                onClick={() => handleClickValidar('sendToERP')}
                testbutton={I18n.t('Confirmar')}
                onClick2={() => setShowSendToERP(false)}
              />
            ) : null}
          </div>
          <div className={styles.linea} />
          <div className={styles.flex} style={{ padding: '15px 0px  0px  0px' }}>
            <SectionTitle text={I18n.t('observaciones')} className={styles.SectionPotencias} />
          </div>
          <div className={styles.containerForm}>
            {formFieldsObservation.map((formGroup: FormGroupProps) => (
              <FormGroup
                key={formGroup.id}
                label={formGroup.label}
                type={formGroup.type}
                id={formGroup.id}
                path={formGroup.path}
                name={formGroup.name}
                placeholder={formGroup.placeholder}
                value={formGroup.value}
                required={formGroup.required}
                readOnly={formGroup.readOnly}
                className={formGroup.className}
                onChange={handleChange}
                maxLenght={200}
              />
            ))}
          </div>
          <div className={styles.eliminarInscriot}>
            <Button
              className={styles.viewmore_button}
              variant="reddelete"
              type="button"
              onClick={() => {
                toggleSwitch();
              }}
              text={I18n.t(`InscritosDetalle.deleteInscrito`)}
            />
          </div>
          {isSwitchOn && (
            <ModalMatcher
              title={`${I18n.t('InscritosDetalle.mensajeModalDelete')} ${installation?.nombre}  ${
                installation?.apellidos
              } NIF: ${installation?.nif}`}
              body={`${I18n.t('InscritosDetalle.mensajeModalDelete')} ${installation?.nombre}  ${
                installation?.apellidos
              } NIF: ${installation?.nif}`}
              onClick={() => deleteConfiguracion(installation?.id)}
              testbutton={I18n.t('Confirmar')}
              loading={loadingDelete}
              onClick2={toggleSwitch}
            />
          )}
          {/* {installation?.validado === 'no' ? ( */}
          <div className={styles.actions}>
            {communityID === undefined ? (
              <Button
                type="button"
                onClick={(): Promise<void> => navigate('/tecnic/inscritos')}
                variant="secondary"
                text={I18n.t('Cancel')}
              />
            ) : (
              <Button
                type="button"
                onClick={(): Promise<void> => navigate(`/tecnic/communities/${communityID}/enrolled`)}
                variant="secondary"
                text={I18n.t('Cancel')}
              />
            )}

            <LoadingButton
              type="submit"
              text={I18n.t('Save')}
              disabled={submitDisabled}
              loading={loading}
              onClick={() => handleClick()}
            />
          </div>
          {/* ) : null} */}
        </>
      )}
    </div>
  );
};
